import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.selectedFactoryName.includes('外來監控') ? _c('div', {
    staticClass: "pageTitle"
  }, [_vm._v(" " + _vm._s(_vm.selectedFactoryName) + " ")]) : _c('div', {
    staticClass: "realtime_Multiselect"
  }, [_c('multiselect', {
    ref: "multiselectRef",
    attrs: {
      "options": _vm.getFactoryInfoNameList,
      "placeholder": "案場名稱",
      "max-height": 600,
      "preserveSearch": true,
      "allow-empty": false,
      "show-labels": false,
      "clear-on-select": false
    },
    on: {
      "select": _vm.handleSelectFactoryName,
      "open": _vm.handleOpenFactoryNameSelection
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(props) {
        return [_c('span', [_vm._v(_vm._s(props.option))]), _c('i', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip",
            value: '複製名稱',
            expression: "'複製名稱'"
          }],
          staticClass: "far fa-copy copy-button ml-4",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.copyToClipboard(props.option);
            }
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectedFactoryName,
      callback: function callback($$v) {
        _vm.selectedFactoryName = $$v;
      },
      expression: "selectedFactoryName"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v(" 查詢無資料，請重新搜尋 ")])]), _vm.routeQuery.demo === 'true' && _vm.hasRoleAdmin ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.right",
      value: _vm.tooltipDisplaySiteName(_vm.routeQuery.demo),
      expression: "tooltipDisplaySiteName(routeQuery.demo)",
      modifiers: {
        "right": true
      }
    }],
    staticClass: "ml-2 peekDisplayNameBtn"
  }, [_c('i', {
    staticClass: "fas fa-search"
  })]) : _vm._e()], 1), _c('div', [_c('div', {
    staticClass: "category-wrapper py-3"
  }, [_vm.roleComponentMenu.includes('siteinfodetail') && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/siteinfodetail"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'siteinfodetail'
    }
  }, [_c('i', {
    staticClass: "fa fa-cloud m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.energyInfo")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('devicestatus') && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/devicestatus"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'devicestatus'
    }
  }, [_c('i', {
    staticClass: "fa fa-check-circle m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.DeviceStatus")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('devicehistory') && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/devicehistory"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'devicehistory'
    }
  }, [_c('i', {
    staticClass: "fa fa-history m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.DeviceHistory")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('dataanalysis') && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/dataanalysis"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'dataanalysis'
    }
  }, [_c('i', {
    staticClass: "fa fa-flask m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.DataAnalysis")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('mpptanalysis') && _vm.routeQuery.demo === 'false' && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/mpptanalysis"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'mpptanalysis'
    }
  }, [_c('i', {
    staticClass: "fa fa-bolt m-5px"
  }), _vm._v("MPPT分析 ")])]) : _vm._e(), _vm.roleComponentMenu.includes('dataexcel') && _vm.routeQuery.demo === 'false' ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/dataexcel"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'dataexcel'
    }
  }, [_c('i', {
    staticClass: "fas fa-drafting-compass m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.DesignInfo")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('investmentanalysis') && _vm.routeQuery.demo === 'false' ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/investmentanalysis"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'investmentanalysis'
    }
  }, [_c('i', {
    staticClass: "fas fa-chart-line m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.InvestmentAnalysis")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('monthlyreport') && _vm.routeQuery.demo === 'false' && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/monthlyreport"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'monthlyreport'
    }
  }, [_c('i', {
    staticClass: "fas fa-file m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.report")) + " ")])]) : _vm._e(), _vm.roleComponentMenu.includes('otherinformation') && _vm.routeQuery.demo === 'false' && !_vm.isForeignFactory ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/otherinformation"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'otherinformation'
    }
  }, [_c('i', {
    staticClass: "fas fa-th-list m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.otherInfo")) + " ")])]) : _vm._e(), false ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/deviceinfo"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'deviceinfo'
    }
  }, [_c('i', {
    staticClass: "fas fa-info-circle m-5px"
  }), _vm._v("設備資訊 ")])]) : _vm._e(), _vm.liveEvent ? _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/videostream"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'videostream'
    }
  }, [_c('i', {
    staticClass: "fas fa-video m-5px"
  }), _vm._v("影像串流 ")])]) : _vm._e(), _c('router-link', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/pvstringfailureanalysis/powerfee"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "info-category",
    class: {
      active: _vm.path === 'pvstringfailureanalysis'
    }
  }, [_c('i', {
    staticClass: "fa fa-check-circle m-5px"
  }), _vm._v(_vm._s(_vm.$t("siteInfo.FailureAnalysis")) + " ")])])], 1), false ? _c('b-col', {
    staticClass: "p-0",
    attrs: {
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "marquee",
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_c('span', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.$t("siteInfo.LatestInformation")) + _vm._s(_vm.marqueeMsg) + " ")])])]) : _vm._e()], 1), _c('router-view')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };